<script>
export default {
  name: "CumulusCard",
  props: ["log"],
};
</script>

<template>
  <div class="col">
    <div class="card">
      <div class="card-body placeholder-glow">
        <h5 class="card-title">Pilvisyys</h5>
        <div class="card-text" :class="{ placeholder: !log.datetime }">
          <span v-if="log">Mahdollisen kumpupilven alaosan etäisyys maasta on noin
            {{ log.ca60savg }} metriä.</span>
          <span v-if="!log">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
